import React from 'react'
import styled from 'styled-components'
import Main from '../components/layout'
import Head from '../components/head'
import AboutImage from '../../assets/index.svg'
import { Heading1, A, StyledLink } from '../components/typography'

const Li = styled.li`
  position: relative;
  padding-bottom: 1.25em;
  margin-bottom: 1.25em;
  background-image: linear-gradient(
    to right,
    var(--green) 33%,
    rgba(255, 255, 255) 0%
  );
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;

  &:last-child {
    background-image: none;
  }
`

const Plant = styled(AboutImage)`
  position: fixed;
  right: 9em;
  bottom: 14em;
  transform: scale(3.7) rotate(15deg);
  z-index: -1;
  opacity: 0.7;
  transition: all 0.3s ease-in;

  ellipse {
    fill: var(--cream-dark);
    fill-opacity: 1;
  }

  .leaf-primary-dark {
    fill: var(--green-dark);
  }

  .leaf-primary {
    fill: var(--green);
  }

  .leaf-primary-light {
    fill: var(--green-light);
  }

  .leaf-secondary {
    fill: #ffe8e5;
  }

  .leaf-tertiary {
    fill: var(--cream);
  }

  .white {
    fill: var(--white);
  }

  .stem {
    fill: #e7ffd9;
  }

  @media (max-width: 100em) {
    opacity: 0.6;
  }

  @media (max-width: 40em) {
    opacity: 0.9;
    position: initial;
    transform: scale(1);
    max-width: 100%;

    ellipse {
      display: none;
    }
  }
`

const IndexHeader = styled.header`
  position: relative;
  margin: 0 var(--bleed-side);
  display: flex;
  justify-content: center;
`

const IndexContent = styled.article`
  max-width: 40em;
  display: flex;
  flex-grow: 1;
  align-items: flex-end;

  @media (max-width: 100em) {
    align-items: center;
  }

  @media (max-width: 40em) {
    align-items: flex-start;
  }
`

const ContentWrapper = styled.div`
  padding: 2em var(--bleed-side);

  @media (max-width: 100em) {
    background: rgba(255, 250, 240, 0.7);
    padding-top: 3em;
  }

  @media (max-width: 40em) {
    background: none;
    padding-top: 0;
  }
`

function IndexPage() {
  return (
    <Main>
      <Head title="Winter" />

      <IndexHeader>
        <Plant />
      </IndexHeader>

      <IndexContent>
        <ContentWrapper>
          <Heading1>Hi, I’m Winter</Heading1>
          <ul>
            <Li>Lead Design Technologist, Design Systems @ Carta</Li>
            <Li>
              As a front-end engineer, I'm passionate about universal design
              principles. I've learned from my travels how challenging it can be
              to navigate an unfamiliar world. That's why I aim to create
              simple, consistent, and scalable designs that provide intuitive
              digital experiences.
            </Li>

            <Li>♡ Aerial, Books, Baking, Cats, Gaming, Plants, Origami</Li>
          </ul>
        </ContentWrapper>
      </IndexContent>
    </Main>
  )
}

export default IndexPage
